// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_poP2f{display:flex;flex-direction:row;margin-bottom:1px;width:100%}.container_poP2f.thickBoi_Q4C2p{margin-bottom:.25rem}.container_poP2f:last-child{margin-bottom:0}.widget_RSW9j{display:flex;flex-direction:column;width:25%}.widget_RSW9j:not(:last-child){margin-right:1px;width:calc(25% - 1px)}.thickBoi_Q4C2p .widget_RSW9j:not(:last-child){margin-right:.25rem;width:calc(25% - .25rem)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_poP2f",
	"thickBoi": "thickBoi_Q4C2p",
	"widget": "widget_RSW9j"
};
module.exports = ___CSS_LOADER_EXPORT___;
